import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardHeader,
  TextField,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useFormik } from "formik";
import * as Yup from "yup"; // Import Yup for validation
import * as api from "src/services";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";
import Typography from "src/theme/overrides/Typography";

export default function EditSeriesNumber({ editId, editSerialNumber, editProductId, editLabel, handleClearEdit }) {
  const [options, setOptions] = useState([]);
  const { t } = useTranslation("amcs");
  const [loading, setloading] = useState(false);
  const [productName, setProductName] = useState("");

  const { mutate } = useMutation((editProductId && editSerialNumber ? api.UpdateSerialKey : api.AddSerialKey), {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      window.location.reload();
    },
    onError: (err) => {
      toast.error(err.response.data.message || "Something went wrong!");
      setloading(false);
    },
  });

  // Fetch data based on product name
  const getData = async (product = "") => {
    const { data } = await api.getSearchProducts(product);
    const newOptions = data.map((el) => ({
      value: el?._id + "",
      label: el?.name,
    }));
    setOptions(newOptions);
  };


  // Formik setup
  const formik = useFormik({
    initialValues: {
      product: null, // Start with null for Autocomplete
      serialNumber: '',
    },
    validationSchema: Yup.object({
      product: Yup.object().nullable().required(t('product-is-required')), // Change validation to check for object
      serialNumber: Yup.string().required(t("serial-number-is-required")),
    }),
    onSubmit: (values) => {
      const { product: { value }, serialNumber } = values;
      setloading(true);
      mutate({ id: editId, productId: value, serialNumber });
    },
  });

  // Handle product change with debouncing
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getData(); // Only search if label exists
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [formik.values.product]);

  useEffect(() => {
    if (editProductId && editSerialNumber) {
      formik.setFieldValue('serialNumber', editSerialNumber)
      formik.setFieldValue('product', { value: editProductId, label: editLabel });
    }
  }, [editProductId, editSerialNumber])

  return (
    <Box>
      <Card sx={{ px: 2, py: 2.5 }}>
        <p style={{ marginBottom: "20px" }}>{editProductId && editSerialNumber ? "Update Serial Number" : "Add Serial Number"}</p>
        <form onSubmit={formik.handleSubmit}>
          <Autocomplete
            disablePortal
            sx={{ mb: 1.5 }}
            options={options}
            value={formik.values.product}
            getOptionLabel={(option) => option.label}
            onChange={(event, value) => {
              formik.setFieldValue('product', value); // Set selected object
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                error={Boolean(formik.touched.product && formik.errors.product)}
                helperText={formik.touched.product && formik.errors.product}
              />
            )}
          />
          <TextField
            type="text"
            sx={{ mb: 1.5, mt: 1.5 }}
            label='Serial Number'
            fullWidth
            name="serialNumber"
            value={formik.values.serialNumber}
            onChange={() => { formik.handleChange }}
            error={Boolean(formik.touched.serialNumber && formik.errors.serialNumber)}
            helperText={formik.touched.serialNumber && formik.errors.serialNumber}
          />
          <Box sx={{ mt: 1.5 }}>
            <Button size="large" variant="contained" type="submit">
              {editProductId && editSerialNumber ? 'Update' : 'Add'}
            </Button>
            {editProductId && editSerialNumber ? < Button size="large" sx={{ ml: 1.5 }} variant="outlined" type="button" onClick={() => {
              handleClearEdit()
              formik.setFieldValue('product', null)
              formik.setFieldValue('serialNumber', "")
            }}>
              Clear
            </Button> : <></>}
          </Box>
        </form>
      </Card>
    </Box >
  );
}
