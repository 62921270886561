import http from "./http";

export const getProducts = async (page, search) => {
  const { data: response } = await http.get(
    `/admin/products?search=${search}&page=${page}`
  );
  return response;
};
export const getSerialList = async (page, search) => {
  const { data: response } = await http.get(
    `/admin/amcs/serialkeys?search=${search}&page=${page}`
  );
  return response;
}

export const getAllProducts = async () => {
  const { data } = await http.get(`/admin/products/all`);
  return data;
};
export const getFeaturedProducts = async () => {
  const { data: response } = await http.get("/admin/products/featured");
  return response;
};
export const getProductDetails = async (name) => {
  const { data: response } = await http.get(`/admin/products/${name}`);
  return response;
};
export const newProduct = async (payload) => {
  const { data: response } = await http.post(`/admin/products`, payload);
  return response;
};
export const updateProduct = async ({ id, ...payload }) => {
  const { data: response } = await http.put(`/admin/products/${id}`, payload);
  return response;
};
export const deleteProduct = async (id) => {
  const { data: response } = await http.delete(`/admin/products/${id}`);
  return response;
};
export const getUsers = async (page, search) => {
  const { data: response } = await http.get(
    `/admin/users?search=${search}&page=${page}`
  );
  return response;
};

// Add user field admin/clients
export const addClient = async (payload) => {
  const { data: response } = await http.post(`/admin/clients`, payload);
  return response;
};
//edit admin/client
export const EditClient = async (id, payload) => {
  const res = await http.put(`/admin/clients/${id}`, payload);
  return res;
}

export const addUser = async (payload) => {
  const { data: response } = await http.post(`/admin/users`, payload);
  return response;
};
export const getUser = async (id) => {
  const { data: response } = await http.get(`/admin/users/${id}`);
  return response;
};
export const userStatus = async ({ id, ...payload }) => {
  const { data: response } = await http.put(`/admin/users/${id}`, payload);
  return response;
};
export const userDelete = async (id) => {
  const { data: response } = await http.delete(`/admin/users/${id}`);
  return response;
};
export const getCategories = async (page, search) => {
  const { data } = await http.get(
    `/admin/categories?search=${search}&page=${page}`
  );
  return data;
};
export const getCategory = async (id) => {
  const { data } = await http.get(`/admin/categories/${id}`);
  return data;
};
export const getAllCategories = async (id) => {
  const { data } = await http.get(`/admin/categories/all-categories`);
  return data;
};
export const deleteCategory = async (id) => {
  const { data } = await http.delete(`/admin/categories/${id}`);
  return data;
};
export const addCategory = async (payload) => {
  const { data } = await http.post(`/admin/categories`, payload);
  return data;
};
export const updateCategory = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/categories/${id}`, payload);
  return data;
};

export const getSubCategories = async (page, search) => {
  const { data } = await http.get(
    `/admin/categories/sub-categories?search=${search}&page=${page}`
  );
  return data;
};
export const getSubCategory = async (id) => {
  const { data } = await http.get(`/admin/categories/sub-categories/${id}`);
  return data;
};
export const getAllSubCategories = async (id) => {
  const { data } = await http.get(
    `/admin/categories/sub-categories/all-categories`
  );
  return data;
};
export const deleteSubCategory = async (id) => {
  const { data } = await http.delete(`/admin/categories/sub-categories/${id}`);
  return data;
};
export const addSubCategory = async (payload) => {
  const { data } = await http.post(`/admin/categories/sub-categories`, payload);
  return data;
};
export const updateSubCategory = async ({ id, ...payload }) => {
  const { data } = await http.put(
    `/admin/categories/sub-categories/${id}`,
    payload
  );
  return data;
};

export const getOrders = async (page, search, startDate, endDate, productName, deliveryStatus, paymentStatus) => {
  console.log("paymentStatus:", paymentStatus)
  const { data } = await http.get(
    `/admin/orders?search=${search}&page=${page}&startDate=${startDate}&endDate=${endDate}&deliveryStatus=${deliveryStatus}&productName=${productName}&paymentStatus=${paymentStatus}`
  );
  return data;
};
export const getSingleOrder = async (id) => {
  const { data } = await http.get(`/admin/orders/${id}`);
  return data;
};
export const deleteOrder = async (id) => {
  const { data } = await http.delete(`/admin/orders/${id}`);
  return data;
};
export const updateOrderStatus = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/orders/${id}`, payload);
  return data;
};
export const dashboardData = async () => {
  const { data } = await http.get("/admin/dashboard");
  return data;
};
export const login = async (payload) => {
  const { data } = await http.post("/admin/auth/login", payload);
  return data;
};
export const shippingCharges = async (payload) => {
  const { data } = await http.post("/admin/deliverycharges", payload);
  return data;
};
export const register = async (payload) => {
  const { data } = await http.post(`/admin/auth/register`, payload);
  return data;
};
export const getProfile = async () => {
  const { data } = await http.get("/admin/profile");
  return data;
};
export const updateProfile = async (payload) => {
  const { data } = await http.put("/admin/profile", payload);
  return data;
};

export const deteleFiles = async (payload) => {
  const { data } = await http.post("/admin/delete", payload);
  return data;
};
export const singleDeleteFile = async (payload) => {
  const { data } = await http.delete(`/admin/delete`, { data: payload });
  return data;
};
export const selectCurrency = async (payload) => {
  const { data } = await http.put("admin/users/select-currency", payload);
  return data;
};
export const changePassword = async (payload) => {
  const { data } = await http.put("/admin/users/change-password", payload);
  return data;
};
export const forgetPassword = async (payload) => {
  const { data } = await http.post("/admin/auth/forget-password", {
    email: payload,
  });
  return data;
};
export const resetPassword = async ({ newPassword, token }) => {
  const { data } = await http.post("/admin/auth/reset-password", {
    newPassword: newPassword,
    token: token,
  });
  return data;
};
export const getNotification = async (page) => {
  const { data } = await http.get(`/admin/notifications?page=${page}`, {});
  return data;
};
export const getUserNotification = async () => {
  const { data } = await http.get(`/admin/notifications/user?page=${1}`);
  return data;
};
export const addUserNotification = async (payload) => {
  const { data } = await http.post(`/admin/notifications/user`, payload);
  return data;
};
export const editUserNotification = async (payload) => {
  const { data } = await http.put(`/admin/notifications/user`, payload);
  return data;
};
export const deleteUserNotification = async (id) => {
  const { data } = await http.delete(`/admin/notifications/user?id=${id}`);
  return data;
};
export const getNewsletter = async (page) => {
  const { data } = await http.get(`/admin/newsletter?page=${page}`);
  return data;
};
export const getRoles = async () => {
  const { data: response } = await http.get(`/admin/roles`);
  return response;
};
export const addRole = async (payload) => {
  const { data } = await http.post("/admin/roles", payload);
  return data;
};
export const deleteRole = async (id) => {
  const { data } = await http.delete(`/admin/roles/${id}`);
  return data;
};
export const getPrimarySlider = async () => {
  const { data: response } = await http.get(`/admin/home-slider`);
  return response;
};

export const createPrimarySlider = async (payload) => {
  const { data: response } = await http.post(`/admin/home-slider`, {
    ...payload,
    clientId: "123-123-124",
  });
  return response;
};

export const getPrimarySlide = async (id) => {
  const { data } = await http.get(`/admin/home-slider/${id}`);
  return data;
};

export const updatePrimarySlider = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/home-slider/${id}`, payload);
  return data;
};

export const getHomeBanners = async () => {
  const { data } = await http.get(`/admin/home-banners`);
  return data;
};
export const updateHomeBanners = async (payload) => {
  const { data } = await http.post(`/admin/home-banners`, payload);
  return data;
};

// BRANDS ----------
export const getBrands = async (page, search) => {
  const { data } = await http.get(
    `/admin/brands?search=${search}&page=${page}`
  );
  return data;
};
export const getAllBrands = async () => {
  const { data } = await http.get(`/admin/brands/all-brands`);
  return data;
};
export const newBrand = async (payload) => {
  const { data: response } = await http.post(`/admin/brands`, payload);
  return response;
};
export const updateBrand = async ({ id, ...payload }) => {
  const { data: response } = await http.put(`/admin/brands/${id}`, payload);
  return response;
};
export const getBrand = async (id) => {
  const { data } = await http.get(`/admin/brands/${id}`);
  return data;
};
export const deleteBrand = async (id) => {
  const { data } = await http.delete(`/admin/brands/${id}`);
  return data;
};

// AMC ----------
export const getAmcs = async (page, search) => {
  const { data } = await http.get(`/admin/amcs?search=${search}&page=${page}`);
  return data;
};

export const newAmc = async (payload) => {
  const { data } = await http.post(`/admin/amcs`, payload);
  return data;
};

export const updateAmcs = async ({ id, ...payload }) => {
  const { data } = await http.put(`/admin/amcs/${id}`, payload);
  return data;
};

export const getAmc = async (id) => {
  const { data } = await http.get(`/admin/amcs/${id}`);
  return data;
};
export const deleteAmc = async (id) => {
  const { data } = await http.delete(`/admin/amcs/${id}`);
  return data;
};

//customer
export const getCustomerList = async () => {
  const { data } = await http.get(`/admin/customer`);
  return data;
}
//shipping charges

export const getShippingCharges = async () => {
  const { data } = await http.get("/admin/comman-setting");
  return data;
}

//place order
export const placeOrder = async (obj) => {
  const res = await http.post("/orders", obj);
  return res
}


//export 
export const AMCRequestList = async (page, search, startDate, endDate, amcStatus) => {
  const res = await http.get(`/admin/amcwithorder?page=${page}&search=${search}&startDate=${startDate}&endDate=${endDate}&amcStatus=${amcStatus}`)
  return res;
}


//amc aprooval

export const AMCRequestListAprooval = async (id, obj) => {
  const { data } = await http.put(`/admin/amcwithorder/${id}`, obj)
  return data;
}

//clients
export const GetAdminUsers = async () => {
  const { data } = await http.get(`/admin/clients`)
  console.log("data:", data)
  return data;
}

// get product list in amc for dropdown gangaram 7/09/2024

export const getSearchProducts = async (search) => {
  const { data } = await http.get(`/admin/amcs/products?search=${search}`);
  return data;
}

export const AddSerialKey = async (payload) => {
  const res = await http.post("/admin/amcs/serialkeys", payload);
  return res;
}

export const UpdateSerialKey = async (payload) => {
  const res = await http.put("/admin/amcs/serialkeys", payload);
  return res;
}

export const DeleteSerialKey = async (id) => {
  const res = await http.delete(`/admin/amcs/serialkeys?deleteId=${id}`);
  return res;
}
