import {
    Card,
    Chip,
    Grid,
    Stack,
    Radio,
    Select,
    TextField,
    Typography,
    RadioGroup,
    FormControl,
    Autocomplete,
    InputAdornment,
    FormHelperText,
    FormControlLabel,
    FormGroup,
    Skeleton,
    Switch,
} from "@mui/material";
import React, { useState } from 'react';
import * as api from "src/services";
import * as Yup from "yup";
import { Form, FormikProvider, useFormik } from "formik";
import { styled } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

const shippingNewForm = () => {
    const { t } = useTranslation("shipping");
    const [loading, setloading] = useState(false);
    const LabelStyle = styled(Typography)(({ theme }) => ({
        ...theme.typography.subtitle2,
        color: theme.palette.text.secondary,
        marginBottom: theme.spacing(1),
        span: {
            fontSize: 12,
            float: "right",
            fontWeight: 400,
        },
    }));

    const { mutate } = useMutation(api.shippingCharges, {
        onSuccess: (data) => {
            toast.success(t("shipping-charges-updated-successfully"));
            setloading(false);
        },
        onError: (err) => {
            toast.error(err.response.data.message || "Something went wrong!");
            setloading(false);
        },
    });

    const shippingChargesSchema = Yup.object().shape({
        shippingCharges: Yup.number()
            .required(t("shipping-charges-required"))
            .min(0, t("shipping-charges-must-be-greater-than-zero"))
    });

    const formik = useFormik({
        initialValues: {
            shippingCharges: ""
        },
        validationSchema: shippingChargesSchema,
        onSubmit: async (values) => {
            const { shippingCharges } = values;
            setloading(true);
            mutate({ shippingCharges });
        },
    });
    const {
        errors,
        values,
        touched,
        handleSubmit,
        setFieldValue,
        getFieldProps,
    } = formik;

    return (
        <FormikProvider value={formik}>
            <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card sx={{ p: 3 }}>
                            <Stack spacing={3} marginBottom={3}>
                                <div>
                                    <LabelStyle>{t("Shipping Charges")}</LabelStyle>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        {...getFieldProps("shippingCharges")}
                                        error={Boolean(touched.shippingCharges && errors.shippingCharges)}
                                        helperText={touched.shippingCharges && errors.shippingCharges}
                                    />
                                </div>
                            </Stack>
                            <LoadingButton

                                type="submit"
                                fullWidth
                                variant="contained"
                                size="large"
                                loading={loading}
                            >
                                {t("save")}
                            </LoadingButton>
                        </Card>
                    </Grid>
                </Grid>
            </Form>
        </FormikProvider>
    )
}

export default shippingNewForm